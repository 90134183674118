import { IBuyConfirmInfo } from '../../buy';

import { IP2pDeal } from '7-entities/p2p';

import { LangVariants } from '8-shared/lib/i18n';

interface IProps {
  item?: IP2pDeal;
  count?: number;
  lang: LangVariants;
}
export const getInfoOrder = ({ item, count, lang }: IProps): IBuyConfirmInfo => {
  // hardcoded p2p buy commission
  const commission = 3;
  const quantity = count || 0;
  const priceLot = Number(item?.price_per_security || 0);
  const priceWithoutCommission = quantity * priceLot;
  const commissionPrice = (priceWithoutCommission * commission) / 100;
  const totalPrice = priceWithoutCommission + commissionPrice;

  return {
    totalPrice,
    commission,
    quantity,
    priceWithoutCommission,
    commissionPrice,
    priceLot,
  };
};
