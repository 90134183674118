import React from 'react';

import { IDigitalSafeOperation } from '7-entities/user';
import { useLocales } from '8-shared/lib/i18n';
import { Button, Modal } from '8-shared/ui';
import { convertNumber, decimalPlaces } from '8-shared/lib/utils';

interface IProps {
  opened: boolean;
  onClose: () => void;
  onSubmit: () => void;
  safe: IDigitalSafeOperation;
  isLoading: boolean;
}

const DIGITAL_SAFE_COMMISSION = 0.2;

export const ConfirmCloseDigitalSafeModal = ({ opened, onClose, safe, onSubmit, isLoading }: IProps) => {
  const { t } = useLocales('digitalSafe');
  const isNeedCommission = Number(safe?.total) === 0;
  const commission = isNeedCommission ? Number(safe.amount) * DIGITAL_SAFE_COMMISSION : 0;
  const income = convertNumber(Number(safe?.total));
  const amountWithProfit = Number(safe?.amount) + Number(safe?.total);
  const total = isNeedCommission ? amountWithProfit - commission : amountWithProfit;

  return (
    <Modal size={'lg'} opened={opened} onClose={onClose} title={t('close_modals.title')}>
      <div className={'text-2 font-500 text-black_text_opacity_70 flex flex-col gap-5 mt-10'}>
        <div className={'flex flex-col gap-3 border-2 border-gray p-3 rounded-2xl'}>
          <div className={'flex justify-between'}>
            <div>{t('close_modals.confirm.amount')}</div>
            <div>{`$ ${decimalPlaces(Number(safe?.amount))}`}</div>
          </div>
          <div className={'flex justify-between'}>
            <div>{t('close_modals.confirm.income')}</div>
            <div>{`$ ${decimalPlaces(income)}`}</div>
          </div>
          <div className={'w-full h-px border-t-2 border-gray'} />
          <div className={'flex justify-between items-center'}>
            <div>{t('close_modals.confirm.commission')}</div>
            <div>{`$ ${decimalPlaces(commission)}`}</div>
          </div>
        </div>
        <div className={'flex justify-between items-center'}>
          <div>{t('open_modals.confirm.total_price')}</div>
          <div className={'flex flex-col gap-1 text-right'}>
            <div>$ {decimalPlaces(total)}</div>
          </div>
        </div>
        <div className={'flex gap-3'}>
          <Button onClick={onSubmit} className={'bg-blue w-full'} loading={isLoading} disabled={isLoading}>
            {t('close_modals.confirm.submit_btn')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
